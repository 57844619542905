import Vue from 'vue'
import App from './App.vue'
import Message from './utils/messageEx'
import {Input, Loading} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
// el-button防止多次点击指令
import buttonDirective from './directive/preventReClick'


Vue.directive('preventReClick', buttonDirective)
Vue.config.productionTip = false
Vue.prototype.$message = Message
Vue.use(Loading)
Vue.use(Input)

new Vue({
  render: h => h(App),
}).$mount('#app')
