import axios from 'axios'

const config = require('../config')
import Message from './messageEx'
// create an axios instance
const request = axios.create({
    // url = base url + request url
    baseURL: config.PROXY_TARGET + config.BASE_API,
    // baseURL: '/api',
    timeout: 120000 // request timeout
})

// 发送请求params每个字段限制的最大长度
const MAX_PARAMS_LEN = 128

// 请求拦截
request.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么
        config.headers['X-Requested-With'] = 'XMLHttpRequest'
        if (config.params) {
            for (const key in config.params) {
                // eslint-disable-next-line no-prototype-builtins
                if (config.params.hasOwnProperty(key)) {
                    const value = config.params[key]
                    if (config.method !== 'post' && typeof value === 'string' && value.length > MAX_PARAMS_LEN) {
                        config.params[key] = value.substring(0, MAX_PARAMS_LEN)
                    }
                }
            }
        }
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)
// 响应拦截
request.interceptors.response.use(
    function (response) {

        return Promise.resolve(response.data)
    },
    function (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            console.log(error.response)
            if (error.response.data != null && (error.response.data.message != null || error.response.data.message != '')) {
                Message({
                    message: error.response.data.msg,
                    type: 'error',
                    duration: 5000
                })
            } else {
                Message({
                    message: '未登录或系统权限不够',
                    type: 'error',
                    duration: 5000
                })
            }
        } else if (error.response && error.response.status === 500) {
            Message({
                message: '系统后台错误',
                type: 'error',
                duration: 5000
            })
            return Promise.reject({
                code: '0',
                message: '系统后台错误'
            })
        } else if (error.response && error.response.status === 413) {
            Message({
                message: '查询条件输入过多',
                type: 'error',
                duration: 5000
            })
            return Promise.reject({
                code: '0',
                message: '查询条件输入过多'
            })
        } else {
            Message({
                message: '网络请求错误',
                type: 'error',
                duration: 5000
            })
            return Promise.reject({
                code: '0',
                message: '网络请求错误'
            })
        }
    }
)
export default request
