module.exports = {
  //端口号
  SERVER_PORT: 8090,

  BASE_API: '/api/',
  //PROXY_TARGET: 'https://10.52.74.4:11120/cmsserver/api',
  //API请求地址
  PROXY_TARGET: 'https://saas.api.xiaochuanyun.com',
  //fastdfs文件访问地址
  FASTDFS_TARGET: 'http://10.52.176.6:10011/fastdfs',
  //版本号(手动更改)
  VERSION_NUMBER: '2.24.0.0',
  //项目简称：xye(项目拼音首字母)npm run
  PROJECT_ABBRE: '',
  //发布标识：r(只有发布版本才需要)
  VERSION_FLAG: 'r'
}
