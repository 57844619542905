<template>
  <div class="hello"
       v-loading="showLoading"
       element-loading-text="拼命加载中"
       element-loading-spinner="el-icon-loading"
       element-loading-background="rgba(0, 0, 0, 0.8)">
    <div class="bg">
      <div class="login">
        <div class="login_right">
          <div class="login_right_content" v-show="!showSuccess">
            <div class="content_title">欢迎加入川川云</div>
            <div class="content_item" style="margin-top: 50px"><span class="span">手机号码</span>
              <input class="input_item" type="number" v-model="phone" pattern="^1\d{10}$" required
                     @input="validatePhone"/>
            </div>
            <!--            <input class="content_input_item" style="margin-top: 50px" v-model="phone" placeholder="手机号码"/>-->
            <div class="content_verification"><span class="span">短信验证码</span>
              <input class="verification_input" v-model="verification" pattern="[a-zA-Z0-9]+" required
                     onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" maxlength="10"/>
              <div class="divider"></div>
              <button class="verification_button" @click="sendVerification">{{ sendSmsWord }}</button>
            </div>
            <div class="content_item">
              <span class="span">设置密码</span>
              <input class="input_item" v-model="psw" placeholder="（8-20位数字与字母组合）"
                     pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]{8,20}$" required
                     onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" maxlength="20"/>
            </div>
            <div class="content_item">
              <span class="span">确认密码</span>
              <input class="input_item" v-model="confirm_psw"
                     onkeyup="value=value.replace(/[^\w\.\/]/ig,'')" maxlength="20"/>
            </div>
            <div class="agreement" style="margin-bottom: 50px"><input class="cb" v-model="msg_checked" type="checkbox"/>阅读并同意
              <a href="https://m.ccynice.com/vipagreement.html">《用户协议》</a>和<a
                  href="https://m.ccynice.com/privacy.html">《隐私协议》</a>
            </div>
            <button class="registry" @click="login" v-preventReClick>立即注册</button>
          </div>
          <div class="login_right_success" v-show="showSuccess">
            <img class="success_img" src="../assets/success_icon.png"/>

            <div class="success_title">注册成功！</div>
            <div class="success_msg">新用户免费领云机，赶快下载app领取吧！</div>
            <div class="download" @click="download" v-preventReClick>
              <img src="../assets/down_load.png">
              <span>下载川川云</span>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {register, sendSms} from "@/utils/register";

export default {
  name: 'HelloWorld',
  data() {
    return {
      phone: "",
      verification: "",
      psw: "",
      confirm_psw: "",
      msg_checked: false,
      showLoading: false,
      countdown: 60,
      sendSmsWord: "发送验证码",
      showSuccess: false,
      agentPhone: "291665"

    }
  },
  methods: {
    validatePhone(value1) {
      if (value1.data && !/^\d{0,11}$/.test(value1.data) || !/^\d{0,11}$/.test(this.phone)) {
        this.phone = this.phone.substring(0, this.phone.length - value1.data.length);
      }
    },
    sendVerification() {
      console.log(this.phone, this.phone === "")
      if (this.phone && this.phone === "") {
        this.$message({type: 'warning', message: '手机号不能为空！'})
        return
      }
      sendSms(this.phone).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.startCountdown();
        } else {
          this.$message({type: 'error', message: res.msg})
          if (res.msg === "该用户已注册") {
            this.showSuccess = true;
          }
        }
      }, err => {
        console.log(err)
      })
    },
    startCountdown() {
      console.log("startCountdown in", this.countdown > 0)
      let intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown -= 1;
          this.sendSmsWord = this.countdown + "s后再次发送";
        } else {
          clearInterval(intervalId); // 倒计时结束，清除定时器
          this.countdown = 60;
          this.sendSmsWord = "发送验证码"
        }
      }, 1000);
    },
    download() {
      window.location.href = "https://www.ccynice.com/";
    },
    login() {
      if (this.phone && this.phone === "") {
        this.$message({type: 'warning', message: '手机号不能为空！'})
        return
      }
      if (this.verification && this.verification === "") {
        this.$message({type: 'warning', message: '验证码不能为空！'})
        return
      }
      if (this.psw && this.psw === "") {
        this.$message({type: 'warning', message: '密码不能为空！'})
        return
      }
      if (this.confirm_psw && this.confirm_psw === "") {
        this.$message({type: 'warning', message: '确认密码不能为空！'})
        return
      }
      if (this.psw !== this.confirm_psw) {
        this.$message({type: 'warning', message: '两次密码不一致！'})
        return
      }
      if (!this.msg_checked) {
        this.$message({type: 'warning', message: '请阅读并同意用户协议和隐私协议！'})
        return
      }
      this.showLoading = true;
      let data = "mobile=" + this.phone + "&password=" + this.psw + "&code=" + this.verification + "&inviterUserId=" + this.agentPhone + "&inviterType=2"
      register(data).then(res => {
        console.log(res)
        this.showLoading = false;
        if (res.code === 200) {
          this.$message({type: 'success', message: '注册成功！'})
          this.showSuccess = true;
        } else {
          this.$message({type: 'error', message: '注册失败！' + res.msg})
        }
      }, err => {
        console.log(err)
        this.showLoading = false;
      })
      if (this.phone || this.phone === "") {
        //todo

      }
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
  },
  mounted() {
    // 获取查询参数
    if(this.getQueryString("agentPhone") && this.getQueryString("agentPhone")!==""){
      this.agentPhone = this.getQueryString("agentPhone")
    }
  },
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.hello {
  height: 100%;
  background-color: #519DF7;

  .bg {
    height: 100%;
    background-image: url('https://www.ccynice.com/static/picture/earth.png');
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    .login {
      height: 80%;
      width: 80%;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url('../assets/login_bg.png');
      display: flex;
      align-items: end;
      flex-direction: column;

      .login_right {
        height: 100%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: start;


        .login_right_content {
          height: 50%;
          width: 50%;
          margin-left: 5%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .content_title {
            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: bold;
            font-size: 28px;
            color: #20262B;
            line-height: 40px;
            text-align: left;
            font-style: normal;
          }

          .content_item {
            width: 380px;
            height: 56px;
            background: #F4F6FA;
            border-radius: 10px;
            border: 1px solid #E6E6E6;
            padding: 0 15px;
            display: flex;

            .input_item {
              font-family: SourceHanSansCNVF, SourceHanSansCNVF;
              font-weight: 400;
              font-size: 16px;
              line-height: 26px;
              text-align: left;
              font-style: normal;
              border-width: 0;
              background: transparent;

              padding-left: 20px;
              margin-left: 20px;

              width: 100%;
            }

            .span {
              display: flex;
              align-items: center;
              color: #999999;
              min-width: 70px;
            }
          }

          .content_input_item {
            width: 380px;
            height: 56px;
            background: #F4F6FA;
            border-radius: 10px;
            border: 1px solid #E6E6E6;

            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            line-height: 26px;
            text-align: left;
            font-style: normal;

            padding: 0 15px;
          }

          .agreement {
            display: flex;
            align-items: center;
            width: 380px;
            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: 400;
            font-size: 16px;
            color: #3070FD;
            line-height: 24px;
            text-align: left;
            font-style: normal;

            a {
              color: #3070FD;
            }
          }

          .registry {
            width: 410px;
            height: 56px;
            background: linear-gradient(180deg, #5C8CFF 0%, #0A4CFC 99%);
            box-shadow: 0px 6px 27px 0px rgba(41, 66, 229, 0.46);
            border-radius: 40px;

            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 26px;
            text-align: center;
            font-style: normal;

            border-width: 0px;
          }

          .content_verification {
            width: 380px;
            height: 56px;
            background: #F4F6FA;
            border-radius: 10px;
            border: 1px solid #E6E6E6;
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 0 15px;

            .span {
              display: flex;
              align-items: center;
              color: #999999;
            }

            .verification_input {
              font-family: SourceHanSansCNVF, SourceHanSansCNVF;
              font-weight: 400;
              font-size: 16px;
              color: #999999;
              line-height: 26px;
              text-align: left;
              font-style: normal;

              border-width: 0px;
              background: transparent;
              padding: 0 15px;
              width: 150px;
              height: 100%;
            }

            .divider {
              width: 1px;
              height: 22px;
              background: #D8D8D8;
            }

            .verification_button {
              font-family: SourceHanSansCNVF, SourceHanSansCNVF;
              font-weight: 400;
              font-size: 16px;
              color: #3070FD;
              display: flex;
              line-height: 24px;
              text-align: center;
              font-style: normal;

              border-width: 0px;
              background: transparent;
            }
          }
        }

        .login_right_success {
          height: 50%;
          width: 50%;
          margin-left: 5%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .success_img {
            width: 80px;
            height: 80px;
          }

          .success_title {
            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: bold;
            font-size: 28px;
            color: #20262B;
            text-align: left;
            font-style: normal;
            margin-top: 50px;
          }

          .success_msg {
            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: 400;
            font-size: 16px;
            color: #999999;
            text-align: left;
            font-style: normal;
            margin-top: 5px;
          }

          .download {
            width: 380px;
            height: 60px;
            background: linear-gradient(180deg, #5C8CFF 0%, #0A4CFC 99%);
            box-shadow: 0px 6px 27px 0px rgba(41, 66, 229, 0.46);
            border-radius: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 100px;

            font-family: SourceHanSansCNVF, SourceHanSansCNVF;
            font-weight: 400;
            font-size: 18px;
            color: #FFFFFF;
            font-style: normal;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}

/* 当屏幕宽度小于或等于1920像素时，应用以下样式 */
@media (max-width: 1920px) {
  .hello {
    .bg {
      .login {
        .login_right {
          .login_right_content {
            height: 70%;
          }

          .login_right_success {
            height: 70%;
          }
        }
      }

    }
  }
}

/* 当屏幕宽度大于或等于1920像素时，应用以下样式 */
@media (min-width: 3840px) {
  .hello {
    .bg {
      .login {
        .login_right {
          .login_right_content {
            height: 35%;
          }

          .login_right_success {
            height: 35%;
          }
        }
      }

    }
  }
}
</style>
