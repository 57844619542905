//防止多次点击按钮请求，可自定义时间
export default {
  inserted(el, binding) {
    el.addEventListener('click', () => {
      if (el.type === 'button' && !el.disabled) {
        el.disabled = true
        setTimeout(() => {
          el.disabled = false
        }, binding.value || 1000)
      }
    })
  }
}
