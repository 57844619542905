import request from "@/utils/request";

export function register(data) {
    return request({
        url: '/register?' + data,
        method: 'post'
    })
}

export function sendSms(data) {
    return request({
        url: '/public/sms/send/register?mobile=' + data,
        method: 'post'
    })
}